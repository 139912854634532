var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.groupUserAttributeList)?_c('div',[_c('div',{class:_vm.$style.actionSection},[_c('div',{class:_vm.$style.searchContainer},[_c('b-field',{class:_vm.$style.filterInput},[(
            _vm.checkForPermissions(
              _vm.PermissionsMatrixActionsEnum.READ,
              'group_administration-groups-update_groups-update_user_attributes-read_existing_user_attributes',
              true
            )
          )?_c('b-input',{ref:"searchInput",attrs:{"placeholder":"Search existing attribute(s)","type":"search","icon":"magnify"},model:{value:(_vm.filterInput),callback:function ($$v) {_vm.filterInput=$$v},expression:"filterInput"}}):_vm._e()],1),(
          _vm.isGroupViewAllowed &&
          _vm.checkForPermissions(
            _vm.PermissionsMatrixActionsEnum.CREATE,
            'group_administration-groups-update_groups-update_user_attributes-create_new_user_attributes',
            true
          )
        )?_c('button',{staticClass:"button is-primary",on:{"click":_vm.handleCreateAttribute}},[_vm._v(" Create New Attribute ")]):_vm._e()],1)]),(
      _vm.checkForPermissions(
        _vm.PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-update_groups-update_user_attributes-read_existing_user_attributes',
        true
      )
    )?_c('BasePaginatorHoc',{attrs:{"component":_vm.GroupUserAttributeList,"page":_vm.page,"perPage":_vm.perPage,"sortColumn":_vm.sortColumn,"sortOrder":_vm.sortOrder,"items":_vm.groupUserAttributeList.items,"totalCount":_vm.groupUserAttributeList.totalCount},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event},"updateAttributeList":_vm.routeParam,"sort":function (data) { return _vm.handleSort(data); },"paginate":_vm.handlePaginator}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }