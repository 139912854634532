



























































import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { SortOrder } from '@/jbi-shared/types/search.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  GetGroupUserAttributeListPayload,
  GetGroupUserAttributeListResponse
} from '@/store/modules/admin/types/group-user-attribute.types';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import CreateAttributeStepper from '@/views/AdminUserManagement/components/create-attribute/CreateAttributeStepper.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import GroupUserAttributeList from './components/GroupUserAttributeList.vue';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc
  }
})
export default class AttributeTab extends Vue {
  public perPage: number = 50;
  public page: number = 1;
  public sortColumn: string = 'attributeName';
  public sortOrder: SortOrder = SortOrder.ASC;
  public filterInput: string = '';

  @Action('admin/getGroupUserAttributeList')
  public getGroupUserAttributeList!: (
    params: GetGroupUserAttributeListPayload
  ) => void;

  @State(({ admin }: RootState) => admin.groupUserAttributeList)
  public groupUserAttributeList!: GetGroupUserAttributeListResponse;

  @State(({ admin }: RootState) => admin.apiState.getGroupUserAttributeList)
  public getGroupUserAttributeListStatus!: ApiState;

  @State((state: RootState) => state.rbac.groupTypesUserHasAccessTo)
  public groupTypesUserHasAccessTo!: string[];

  @State((state: RootState) => state.rbac.groupsUserHasAccessTo)
  public groupsUserHasAccessTo!: number[];

  get GroupUserAttributeList(): typeof GroupUserAttributeList {
    return GroupUserAttributeList;
  }

  get isGroupViewAllowed() {
    return this.checkForPermissions(
      PermissionsMatrixActionsEnum.READ,
      'group_administration-groups-read_groups',
      true
    );
  }

  public checkForPermissions(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const groupTypeName = this.groupTypesUserHasAccessTo
      ? this.groupTypesUserHasAccessTo[0]
      : undefined;
    const groupId = this.groupsUserHasAccessTo
      ? this.groupsUserHasAccessTo[0]
      : undefined;
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      groupTypeName,
      groupId,
      undefined,
      skipImplicitCheck
    );
  }

  public handleSort(sortCriteria: {
    sortColumn: string;
    sortOrder: SortOrder.ASC;
  }): void {
    this.sortColumn = sortCriteria.sortColumn;
    this.sortOrder = sortCriteria.sortOrder;

    const params = {
      attributeLabel:
        (this.$route.query.attributeLabel as string) || this.filterInput,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };

    this.getGroupUserAttributeList(params);
  }

  public handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }): void {
    if (this.$route.query.tab !== 'Attributes') {
      return;
    }
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    const params = {
      attributeLabel:
        (this.$route.query.attributeLabel as string) || this.filterInput,
      limit: this.perPage,
      page: this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder: (this.$route.query.sortOrder as SortOrder) || this.sortOrder
    };

    this.getGroupUserAttributeList(params);
  }

  get filterPayload(): GetGroupUserAttributeListPayload {
    return {
      attributeLabel: this.filterInput,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder: (this.$route.query.sortOrder as SortOrder) || this.sortOrder
    };
  }

  @Debounce(600)
  async fetchGroupUserAttributeList() {
    await this.getGroupUserAttributeList(this.filterPayload);
  }

  @Watch('filterInput')
  onFilterInputChange() {
    this.fetchGroupUserAttributeList();
  }

  public handleFilter(): void {
    // Empty function since we're using the watcher now
  }

  public handleCreateAttribute(): void {
    this.$buefy.modal.open({
      parent: this,
      component: CreateAttributeStepper,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {},
      events: {
        onCreateAttribute: () => this.routeParam()
      }
    });
  }

  public mounted(): void {
    if (this.$route.query.tab === 'Attributes') {
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Attributes',
          attributeLabel:
            (this.$route.query.attributeLabel as string) || this.filterInput,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as SortOrder) || this.sortOrder
        }
      });
      this.perPage = +this.$route.query.limit || this.perPage;
      this.page = +this.$route.query.page || this.page;
      this.filterInput =
        (this.$route.query.attributeLabel as string) || this.filterInput;

      this.handlePaginator({ perPage: this.perPage, page: 1 });
    }
  }

  /**
   * Watcher that triggers when the API call is successful.
   * Handles updating the URL and maintaining input focus through the update.
   *
   * The method is async to properly coordinate the sequence:
   * 1. Route change
   * 2. DOM update
   * 3. Focus restoration
   */
  @Watch('getGroupUserAttributeListStatus.success')
  @isDifferent
  @isTruthy
  public async getGroupUserAttributeListSuccess() {
    this.$emit('activateTab');

    // Wait for the route change to complete before trying to restore focus
    // This prevents focus restoration from happening while the page is updating
    await this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Attributes',
        attributeLabel: this.filterInput,
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });

    // Use $nextTick to wait for the DOM to update after the route change
    // This ensures the input element is fully rendered before we try to focus it
    this.$nextTick(() => {
      // Get the Buefy input component using the template ref
      const searchInput = this.$refs.searchInput as any;
      // Navigate the Buefy component structure to find the actual input element
      if (searchInput?.$el) {
        // Focus the input element after all updates are complete
        searchInput.$el.querySelector('input').focus();
      }
    });
  }

  @Watch('getGroupUserAttributeListStatus.error')
  @isDifferent
  @isTruthy
  public getGroupUserAttributeListError() {
    this.$emit('activateTab');
  }

  @Watch('$route.query.tab')
  public routeParam() {
    if (this.$route.query.tab === 'Attributes') {
      this.handlePaginator({ perPage: this.perPage, page: 1 });
    }
  }
}
