






























import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  GroupLevelAttribute,
  GroupLevelAttributeListType
} from '@/jbi-shared/types/jaas-group-level-attributes.types';

@Component({})
export default class ListTypeAttributeOption extends Vue {
  @Prop() attribute!: GroupLevelAttribute;

  get listConfig(): GroupLevelAttributeListType {
    return this.attribute.option as GroupLevelAttributeListType;
  }
}
