
































































































































import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import BasePaginator from '@/components/base/BasePagination.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseTable from '@/components/BaseTable.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import ConfirmDeleteGroupTemplateModal from './ConfirmDeleteGroupTemplateModal.vue';

import {
  GroupTemplateSortSpecs,
  TemplateAttributeDisplayCount
} from '@/store/modules/admin/types/group-level-attribute.types';
import { SortOrder, ApiState } from '@/store/types/general.types';
import { RootState } from '@/store/store';
import { PermissionsMatrixActionsEnum } from '../../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../../../utils/rbac.util';
import { GroupLevelAttributeTemplate } from '@/jbi-shared/types/jaas-group-level-attributes.types';

/**
 * Pagination mixin extension for rendering a list of group level attribute templates.
 * This component is used explicitly in `GroupTemplate.tab.vue`.
 */
@Component({
  components: {
    BaseTable,
    BaseLoading,
    BasePaginator,
    BasePagination,
    SortableTableHeader,
    TableActionDropdown
  }
})
export default class GroupLevelAttributeTemplateList extends mixins(
  PaginationMixin
) {
  @Prop() templates!: GroupLevelAttributeTemplate[];
  @Prop() isLoading!: boolean;
  @Prop() totalNumberOfPage!: number;
  @Prop() totalCount!: number;
  @Prop() isFirstPage!: boolean;
  @Prop() isLastPage!: boolean;
  @Prop() startItemIndex!: number;
  @Prop() endItemIndex!: number;
  @Prop() sortOrder!: SortOrder;
  @Prop() sortColumn!: string;

  @State(
    ({ admin }: RootState) => admin.apiState.getGroupLevelAttributeTemplates
  )
  getGroupLevelAttributeTemplatesApiState!: ApiState;

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  get attributeDisplayCount(): number {
    return TemplateAttributeDisplayCount;
  }

  get PermissionsMatrixActionsEnum() {
    return PermissionsMatrixActionsEnum;
  }

  isViewMoreVisible(template: GroupLevelAttributeTemplate): boolean {
    return (
      template.groupLevelAttributesWithSpecs.length > this.attributeDisplayCount
    );
  }

  // Prevent render error for list
  generateRandomKey(): number {
    return Math.ceil(Math.random() * 999);
  }

  getViewMoreText(template: GroupLevelAttributeTemplate): string {
    return `+${
      template.groupLevelAttributesWithSpecs.length - this.attributeDisplayCount
    } more`;
  }

  getSortOrderOfColumn(name: string): string {
    return this.sortColumn === name ? this.sortOrder : SortOrder.ASC;
  }

  onColumnHeaderClick(columnName: string): void {
    const sortSpecs: GroupTemplateSortSpecs = {
      sortColumn: columnName,
      sortOrder: SortOrder.DESC
    };

    /* invert sort order if the same column is clicked again */
    if (this.sortColumn === columnName) {
      sortSpecs.sortOrder =
        this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }

    this.$emit('sort', sortSpecs);
  }

  confirmDeleteTemplate(template: GroupLevelAttributeTemplate): void {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDeleteGroupTemplateModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        templateName: template.name
      },
      events: {
        confirm: (userConfirm: boolean) => {
          if (userConfirm) {
            this.$emit('deleteTemplate', template.id);
          }
        }
      }
    });
  }

  openGroupTemplateView(templateId: number): void {
    this.$emit('viewTemplate', templateId);
  }

  handleGroupTemplateEdit(templateId: number | undefined): void {
    this.$emit('editTemplate', templateId ?? 0);
  }
}
