





































































import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import BaseLoading from '../../../../../components/base/BaseLoading.vue';
import BasePagination from '../../../../../components/base/BasePagination.vue';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import BaseTable from '../../../../../components/BaseTable.vue';
import SortableTableHeader from '../../../../../components/SortableTableHeader.vue';
import { Group } from '@/store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import GroupRow from '../../../../AdminGroupManagement/components/GroupRow.vue';
import SelectGroupListRow from './SelectGroupListRow.vue';

@Component({
  components: {
    BaseTable,
    BasePagination,
    BaseLoading,
    GroupRow,
    SortableTableHeader,
    SelectGroupListRow
  }
})
export default class SelectGroupList extends mixins(PaginationMixin) {
  @PropSync('selectedGroupIds', { type: Array })
  public syncedSelectedGroupIds!: number[];
  @Prop() public items!: Group[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop({ type: Number, default: 1 }) public firstLvl!: number;
  @Prop(Boolean) public expandGroupFilter!: boolean;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';
  @Prop(Array) public groupsToDisable!: number[];
  @Prop({ type: Array, default: () => [50, 100, 150] })
  public perPageOptions!: number[];

  public groupRowKey: number = Math.floor(Math.random() * 999);
  public isAllGroupSelected: boolean = false;

  @State((state: RootState) => state.admin.apiState.getGroups.success)
  public getGroupsListSuccess!: boolean;

  get groups(): Group[] {
    return this.items || [];
  }

  get firstLvlGroups(): Group[] {
    return this.groups?.filter((group) => group.nlevel === this.firstLvl);
  }

  // All subgroups should be expanded if the parent group is selected
  // OR if selected group has subgroups.
  shouldOverrideExpandFilter(group: Group) {
    const isSelected = this.syncedSelectedGroupIds.includes(group.id);
    const isFirstLevel = group.nlevel === 1;
    const hasChildren = this.getChildren(group).length > 0;

    return isSelected && (isFirstLevel || hasChildren);
  }

  getChildren(group: Group): Group[] {
    const path: string = group.path;
    return this.groups.filter(
      (groupItem) => groupItem.path === path + '.' + groupItem.id
    );
  }

  public onClickHeaderColumn(columnName: string): void {
    if (this.sortColumn === columnName) {
      this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
      return;
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
    this.groupRowKey += 1;
  }

  public getSortOrderOfColumn(name: string): 'ASC' | 'DESC' {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }

  public selectAllGroups(isAllSelected: boolean): void {
    this.isAllGroupSelected = isAllSelected;
    if (isAllSelected) {
      this.groups.forEach(
        (group) =>
          group.nlevel === 1 && this.syncedSelectedGroupIds.push(group.id)
      );
      this.syncedSelectedGroupIds = [...new Set(this.syncedSelectedGroupIds)];
      return;
    }
    const currentPageGroupIds: number[] = this.groups.map((group) => group.id);
    this.syncedSelectedGroupIds = this.syncedSelectedGroupIds.filter(
      (groupId) =>
        !currentPageGroupIds.includes(groupId) ||
        this.groupsToDisable?.includes(groupId)
    );
  }

  @Watch('items')
  onPageGroupChange(): void {
    this.syncedSelectedGroupIds = [...this.syncedSelectedGroupIds];
    this.groupRowKey += 1;
  }

  @Watch('syncedSelectedGroupIds')
  onSelectedGroupUpdate(groupIds: number[]): void {
    const currentPageGroupIds: number[] = this.items
      ? this.items
          .filter((group) => group.nlevel === 1)
          .map((group) => group.id)
      : [];
    this.isAllGroupSelected = currentPageGroupIds.every((groupId) =>
      groupIds.includes(groupId)
    );
  }
}
