





































































import { MyjbiGroupUserAttributeSpec } from '@/jbi-shared/types/myjbi-group.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  FilteredGroupPayload,
  GetGroupsResponsePayload,
  Group
} from '@/store/modules/admin/types/admin.types';
import { UpdateGroupsWithAttributePayload } from '@/store/modules/admin/types/group-user-attribute.types';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import { Pagination } from 'nestjs-typeorm-paginate';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import ManageSettingStep from '../create-attribute/ManageSettingStep.vue';
import SelectGroupStep from '../create-attribute/SelectGroupStep.vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { GROUP_LISTING_SCOPE } from '../../../../store/modules/admin/types/admin.types';

@Component({
  components: {
    SelectGroupStep,
    ManageSettingStep
  }
})
export default class AddGroupStepper extends Vue {
  public initialSelectedGroups: Group[] = [];
  public initialSpecs: MyjbiGroupUserAttributeSpec[] = [];
  public existingGroupIds: number[] = [];
  public selectedGroups: Group[] = [];
  public selectedRequiredGroups: number[] = [];
  public selectedLockGroups: number[] = [];
  public groupStored: Group[] = [];
  public scope: GROUP_LISTING_SCOPE =
    GROUP_LISTING_SCOPE.ADD_USER_ATTRIBUTE_TO_GROUP;

  @Prop(Number) public id!: number;
  @Prop(String) public name!: string;
  @Prop(String) public type!: string;

  @Action('admin/getGroupsFromAttribute')
  getGroupsFromAttribute!: (options: FilteredGroupPayload) => void;

  @State(
    ({ admin }: RootState) => admin.apiState.getGroupsFromAttribute.success
  )
  public getGroupsFromAttributeSuccess!: boolean;

  @State(({ admin }: RootState) => admin.groupsFromAttribute)
  public groupsFromAttribute!: Pagination<Group>;

  @Action('admin/getGroupsByUserAttribute')
  getGroupsByUserAttribute!: (
    options: FilteredGroupPayload
  ) => Promise<GetGroupsResponsePayload>;

  @State(
    ({ admin }: RootState) => admin.apiState.getGroupsByUserAttribute.success
  )
  public getGroupsByUserAttributeSuccess!: boolean;

  @State(({ admin }: RootState) => admin.groupsByUserAttribute)
  public groupsByUserAttribute!: Pagination<Group>;

  @Action('admin/getSpecsByAttributeId')
  getSpecsByAttributeId!: (attributeId: number) => void;

  @State(({ admin }: RootState) => admin.apiState.getSpecsByAttributeId)
  public getSpecsByAttributeIdState!: boolean;

  @State(({ admin }: RootState) => admin.attributeSpecs)
  public attributeSpecs!: MyjbiGroupUserAttributeSpec[];

  @Action('admin/updateGroupsWithAttribute')
  public updateGroupsWithAttribute!: (
    payload: UpdateGroupsWithAttributePayload
  ) => void;

  @State((state: RootState) => state.admin.apiState.updateGroupsWithAttribute)
  public updateGroupsWithAttributeState!: ApiState;

  public activeStep: number = 0;

  public nextStep(): void {
    this.activeStep += 1;
  }

  public prevStep(): void {
    this.activeStep -= 1;
  }

  public closeModal(isUpdateAttribute?: boolean): void {
    if (isUpdateAttribute) {
      this.$emit('updateAttributeList');
    }
    this.$emit('close');
  }

  public onSelectedGroupUpdate(selectedGroups: Group[]): void {
    this.selectedGroups = selectedGroups;
  }

  public onSelectedRequiredGroupIds(selectedGroupIds: number[]): void {
    this.selectedRequiredGroups = selectedGroupIds;
  }

  public onSelectedLockGroupIds(selectedGroupIds: number[]): void {
    this.selectedLockGroups = selectedGroupIds;
  }

  public onGroupStoredUpdate(groupStored: Group[]): void {
    this.groupStored = this.getUniqGroups([
      ...this.groupStored,
      ...groupStored
    ]);
  }

  public getUniqGroups(groups: Group[]): Group[] {
    return [...new Map(groups.map((group) => [group.id, group])).values()];
  }

  public onGroupsAndAttributeSpecs(): void {
    const initialLockedGroups: number[] = this.initialSpecs
      .filter((spec) => spec.lockAttribute)
      .map((spec) => spec.group!.id);
    // Create copy to avoid including vue observer object.
    const selectedLockGroupCopy: number[] = [...this.selectedLockGroups];
    const haveGroupUnlocked: boolean = initialLockedGroups.every((groupId) =>
      selectedLockGroupCopy.includes(groupId)
    );

    this.$buefy.dialog.confirm({
      message: `<p class="buefy-dialog-title">Update Attribute Settings?</p><p class="buefy-dialog-content">Are you sure you want to update the attribute settings? This action cannot be undone after updating.</p>`,
      confirmText: 'Confirm',
      onConfirm: () => {
        if (!haveGroupUnlocked) {
          const dialogElem: Vue = this.$buefy.dialog.confirm({
            message: `<p class="buefy-dialog-title">Remove Lock Attribute?</p><p class="buefy-dialog-content">You are removing lock attribute for some groups, are you sure to proceed?</p>`,
            confirmText: 'Cancel',
            onConfirm: () => undefined,
            canCancel: ['button'],
            cancelText: 'Continue',
            onCancel: () => this.updateGroupsAndAttributeSpecs()
          });
          queueMicrotask(() => {
            const cancelBtn = dialogElem.$el?.querySelector?.(
              `.modal-card > footer > button:first-child`
            );
            cancelBtn?.classList?.add?.(`is-red`);
          });
        } else {
          this.updateGroupsAndAttributeSpecs();
        }
      },
      canCancel: ['button'],
      cancelText: 'Cancel',
      onCancel: undefined
    });
  }

  public updateGroupsAndAttributeSpecs(): void {
    const selectedGroupIds = this.selectedGroups.map((group) => group.id);
    this.updateGroupsWithAttribute({
      attributeId: this.id,
      selectedGroupIds: Array.from(
        new Set([...selectedGroupIds, ...this.existingGroupIds])
      ),
      selectedRequiredGroupIds: this.selectedRequiredGroups,
      selectedLockedGroupIds: this.selectedLockGroups
    });
  }

  public mounted(): void {
    this.getSpecsByAttributeId(this.id);
  }

  @Watch('getSpecsByAttributeIdState.success')
  @isTruthy
  public watchGetSpecsByAttributeIdSuccess() {
    this.initialSpecs = this.attributeSpecs ?? [];
    this.getGroupsByUserAttribute({
      page: 1,
      attributeId: this.id,
      scope: this.scope
    });
  }

  @Watch('getGroupsByUserAttributeSuccess')
  public onGroupsByUserAttributeSuccess() {
    this.selectedLockGroups = this.initialSpecs
      .filter((spec) => spec.lockAttribute)
      .map((spec) => spec.group!.id);
    this.selectedRequiredGroups = this.initialSpecs
      .filter((spec) => spec.required)
      .map((spec) => spec.group!.id);

    const initialSpecGroupIds: number[] = this.initialSpecs
      .filter((spec) => {
        const { nlevel, path } = spec.group!;
        if (nlevel === 1) {
          return true;
        }
        const ancestorIds: number[] = path.split('.').map((id) => +id);
        ancestorIds.pop();
        return this.initialSpecs.every(
          (specItem) => !ancestorIds.includes(specItem.group!.id)
        );
      })
      .map((spec) => spec.group!.id);
    this.initialSelectedGroups = this.groupsByUserAttribute?.items.filter(
      (group) => initialSpecGroupIds.includes(group.id)
    );

    // Only map existing objects if attribute has been assigned to other groups
    if (this.initialSpecs && this.initialSpecs.length > 0) {
      this.existingGroupIds = this.initialSpecs.map((spec) => spec.group!.id);
    }
    this.groupStored = this.groupsByUserAttribute?.items ?? [];
  }

  @Watch('updateGroupsWithAttributeState.error')
  @isTruthy
  public watchCreateAttributeError(): void {
    Toast.open({
      message: 'Something went wrong, try again.',
      type: 'is-danger',
      position: 'is-top'
    });
  }

  @Watch('updateGroupsWithAttributeState.success')
  @isTruthy
  public watchUpdateGroupSuccess(): void {
    const newGroupsAdded: number =
      this.selectedGroups.length - this.initialSelectedGroups.length;
    Toast.open({
      message: `${this.name} added to ${newGroupsAdded} new group(s).`,
      type: 'is-dark',
      position: 'is-top'
    });
    this.closeModal(true);
  }
}
