







































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { isDefaultGroupAttribute } from '@/jbi-shared/util/group-level-attributes.util';
import AttributeButtons from '@/views/AdminGroupSettings/components/GroupLevelAttributes/AttributeButtons.vue';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import DraggableIcon from '@/views/AdminGroupSettings/components/GroupLevelAttributes/DraggableIcon.vue';
import MultiFieldAttributeOption from './MultiFieldAttributeOption.vue';
import ListAttributeOption from './ListAttributeOption.vue';
import ListTypeAttributeOption from './ListTypeAttributeOption.vue';
import { GroupLevelAttributeWithSpec } from '@/jbi-shared/types/jaas-group-level-attributes.types';

@Component({
  components: {
    DraggableIcon,
    AttributeButtons,
    TableActionDropdown,
    MultiFieldAttributeOption,
    ListAttributeOption,
    ListTypeAttributeOption
  }
})
export default class ModifiedAttributeWrapper extends Vue {
  @Prop() attribute!: GroupLevelAttributeWithSpec;
  @Prop() duplicationError!: boolean;
  showField: boolean = false;
  labelErrorMsg: string = '';

  get isDefault() {
    return isDefaultGroupAttribute(this.attribute);
  }

  // Remove the attribute from the list not from the DB
  handleAttributeRemove(): void {
    this.$emit('deleteAttribute');
  }

  handleAttributeEdit(): void {
    this.$emit('editAttribute', this.attribute);
  }

  // Toggle "required" spec of an attribute
  toggleAttributeRequire(attribute: GroupLevelAttributeWithSpec): void {
    attribute.groupLevelAttributeSpec.isRequired = !attribute
      .groupLevelAttributeSpec.isRequired;
  }
}
