





















import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  GroupLevelAttributeOption,
  GroupLevelAttributeWithSpec
} from '@/jbi-shared/types/jaas-group-level-attributes.types';

@Component({})
export default class MultiFieldAttributeOption extends Vue {
  @Prop() attribute!: GroupLevelAttributeWithSpec;
  @Prop({ default: false }) shouldDisable!: boolean;

  get attributeOptionAsAddressType() {
    return this.attribute.option as GroupLevelAttributeOption[];
  }
}
