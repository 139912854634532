






































import { MyjbiGroupUserAttributeSpec } from '@/jbi-shared/types/myjbi-group.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  FilteredGroupPayload,
  GetGroupsResponsePayload,
  Group,
  GROUP_LISTING_SCOPE,
  Pagination
} from '@/store/modules/admin/types/admin.types';
import { UpdateGroupsWithAttributePayload } from '@/store/modules/admin/types/group-user-attribute.types';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import ManageSettingStep from '../create-attribute/ManageSettingStep.vue';
import { ToastProgrammatic as Toast } from 'buefy';

@Component({
  components: {
    ManageSettingStep
  }
})
export default class ManageSettingModal extends Vue {
  public initialSelectedGroups: Group[] = [];
  public initialSpecs: MyjbiGroupUserAttributeSpec[] = [];

  public selectedGroups: Group[] = [];
  public selectedRequiredGroups: number[] = [];
  public selectedLockGroups: number[] = [];
  public initialSelectedRequiredGroups: number[] = [];
  public initialSelectedLockGroups: number[] = [];
  public groupStored: Group[] = [];

  @Prop(Number) public id!: number;
  @Prop(String) public name!: string;

  @Action('admin/updateGroupsWithAttribute')
  public updateGroupsWithAttribute!: (
    payload: UpdateGroupsWithAttributePayload
  ) => void;

  @Action('admin/getSpecsByAttributeId')
  getSpecsByAttributeId!: (attributeId: number) => void;

  @Action('admin/getGroupsFromAttribute')
  getGroupsFromAttribute!: (options: FilteredGroupPayload) => void;

  @Action('admin/getGroupsByUserAttribute')
  getGroupsByUserAttribute!: (
    options: FilteredGroupPayload
  ) => Promise<GetGroupsResponsePayload>;

  @State((state: RootState) => state.admin.apiState.updateGroupsWithAttribute)
  public updateGroupsWithAttributeState!: ApiState;

  @State(({ admin }: RootState) => admin.attributeSpecs)
  public attributeSpecs!: MyjbiGroupUserAttributeSpec[];

  @State(({ admin }: RootState) => admin.groupsFromAttribute)
  public groupsFromAttribute!: Pagination<Group>;

  @State(({ admin }: RootState) => admin.groupsByUserAttribute)
  public groupsByUserAttribute!: Pagination<Group>;

  public get isSettingsChanged(): boolean {
    return (
      JSON.stringify(this.initialSelectedLockGroups.sort()) !==
        JSON.stringify(this.selectedLockGroups.sort()) ||
      JSON.stringify(this.initialSelectedRequiredGroups.sort()) !==
        JSON.stringify(this.selectedRequiredGroups.sort())
    );
  }

  public onSelectedRequiredGroupIds(selectedGroupIds: number[]): void {
    this.selectedRequiredGroups = selectedGroupIds;
  }

  public onSelectedLockGroupIds(selectedGroupIds: number[]): void {
    this.selectedLockGroups = selectedGroupIds;
  }

  public closeModal(isUpdated?: boolean): void {
    if (isUpdated) {
      this.$emit('isUpdated');
    }
    this.$emit('close');
  }

  public onManage(): void {
    const initialLockedGroups: number[] = this.initialSpecs
      .filter((spec) => spec.lockAttribute)
      .map((spec) => spec.group!.id);
    // Create copy to avoid including vue observer object.
    const selectedLockGroupCopy: number[] = [...this.selectedLockGroups];
    const haveGroupUnlocked: boolean = initialLockedGroups.every((groupId) =>
      selectedLockGroupCopy.includes(groupId)
    );

    this.$buefy.dialog.confirm({
      message: `<p class="buefy-dialog-title">Update Attribute Settings?</p><p class="buefy-dialog-content">Are you sure you want to update the attribute settings? This action cannot be undone after updating.</p>`,
      confirmText: 'Confirm',
      onConfirm: () => {
        if (!haveGroupUnlocked) {
          const dialogElem: Vue = this.$buefy.dialog.confirm({
            message: `<p class="buefy-dialog-title">Remove Lock Attribute?</p><p class="buefy-dialog-content">You are removing lock attribute for some groups, are you sure to proceed?</p>`,
            confirmText: 'Cancel',
            onConfirm: () => undefined,
            canCancel: ['button'],
            cancelText: 'Continue',
            onCancel: () => this.updateGroupsWithNewSettings()
          });
          queueMicrotask(() => {
            const cancelBtn = dialogElem.$el?.querySelector?.(
              `.modal-card > footer > button:first-child`
            );
            cancelBtn?.classList?.add?.(`is-red`);
          });
        } else {
          this.updateGroupsWithNewSettings();
        }
      },
      canCancel: ['button'],
      cancelText: 'Cancel',
      onCancel: undefined
    });
  }

  public updateGroupsWithNewSettings() {
    this.updateGroupsWithAttribute({
      attributeId: this.id,
      selectedGroupIds: this.initialSelectedGroups.map((group) => group.id),
      selectedRequiredGroupIds: this.selectedRequiredGroups,
      selectedLockedGroupIds: this.selectedLockGroups
    });
  }

  public mapAttributeSpecIds(): void {
    this.selectedLockGroups = this.initialSpecs
      .filter((spec) => spec.lockAttribute)
      .map((spec) => spec.group!.id);
    this.selectedRequiredGroups = this.initialSpecs
      .filter((spec) => spec.required)
      .map((spec) => spec.group!.id);
    this.initialSelectedLockGroups = this.selectedLockGroups;
    this.initialSelectedRequiredGroups = this.selectedRequiredGroups;
  }

  public getSpecGroupIds(specs: MyjbiGroupUserAttributeSpec[]): number[] {
    return specs
      .filter((spec) => {
        const { nlevel, path } = spec.group!;
        if (nlevel === 1) {
          return true;
        }
        const ancestorIds: number[] = path.split('.').map((id) => +id);
        ancestorIds.pop();
        return this.initialSpecs.every(
          (specItem) => !ancestorIds.includes(specItem.group!.id)
        );
      })
      .map((spec) => spec.group!.id);
  }

  public filterGroupByAncestors(groups: Group[]): Group[] {
    const groupWithAncestors: Group[] = [];
    groups.forEach((group) => {
      if (group.nlevel === 1) {
        return groupWithAncestors.push(group);
      }
      const ancestorPath = group.path.split('' + group.id)[0];
      if (
        groups.filter((groupItem) => groupItem.path + '.' === ancestorPath)
          .length
      ) {
        return groupWithAncestors.push(group);
      }
      groupWithAncestors.push({ ...group, nlevel: 1 });
    });
    return this.getUniqGroups(groupWithAncestors);
  }

  public getUniqGroups(groups: Group[]): Group[] {
    return [...new Map(groups.map((group) => [group.id, group])).values()];
  }

  public mounted(): void {
    this.getSpecsByAttributeId(this.id);
  }

  @Watch('attributeSpecs')
  @isTruthy
  public watchGetSpecsByAttributeIdSuccess(): void {
    this.initialSpecs = this.attributeSpecs ?? [];
    this.getGroupsByUserAttribute({
      page: 1,
      attributeId: this.id,
      scope: GROUP_LISTING_SCOPE.REMOVE_USER_ATTRIBUTE_FROM_GROUP
    });
  }

  @Watch('groupsByUserAttribute')
  @isTruthy
  public onGroupsFromAttributeChange(): void {
    this.mapAttributeSpecIds();
    this.initialSelectedGroups = this.groupsByUserAttribute?.items.filter(
      (group) => this.getSpecGroupIds(this.initialSpecs).includes(group.id)
    );
    this.groupStored = this.groupsByUserAttribute?.items;
    this.selectedGroups = this.initialSelectedGroups;
  }

  @Watch('updateGroupsWithAttributeState.error')
  @isTruthy
  public watchCreateAttributeError(): void {
    Toast.open({
      message: 'Something went wrong, try again.',
      type: 'is-danger',
      position: 'is-top'
    });
  }

  @Watch('updateGroupsWithAttributeState.success')
  @isTruthy
  public watchUpdateGroupSuccess(): void {
    Toast.open({
      message: 'Changes saved.',
      type: 'is-dark',
      position: 'is-top'
    });
    this.closeModal(true);
  }
}
