


























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import {
  clone,
  isDefaultGroupAttribute
} from '@/jbi-shared/util/group-level-attributes.util';
import {
  ApiState,
  SortOrder,
  PaginatorSpecs,
  SortSpecs
} from '@/store/types/general.types';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import {
  GroupLevelAttribute,
  GroupLevelAttributeTemplateMapStatus,
  GroupLevelAttributeWithSpec
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import {
  ActiveDeletedAttributes,
  FilteredGroupLevelAttributesPayload
} from '@/store/modules/admin/types/group-level-attribute.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import AttributesList from './AttributesList.vue';
import { Pagination } from '@/store/modules/admin/types/admin.types';

/**
 * This component allows user to select attributes with specs.
 * - It is INDEPENDENT (can be triggered from anywhere).
 * - It accepts an array of selected specs (optional) for cross-checking.
 *
 * Available events:
 * - `@close`: closes the modal without making changes to the selection
 * - `@updateSelection`: emits the updated attributes with their respective specs
 */
@Component({
  components: {
    BaseLoading,
    BasePaginatorHoc,
    AttributesList
  }
})
export default class ExistingGroupLevelAttributesModal extends Vue {
  /**
   * Business logic:
   *
   * Default attributes (title and logo) should not appear
   * in the selection because the specs and labels of these attributes
   * should be FIXED.
   *
   * Making changes to these attributes has MASSIVE consequences.
   *
   * @IMPORTANT "hiding" them ≠ disabling them.
   *
   * Note:
   * This modal has multiple scenarios where base attributes have to be shown.
   *
   * In those scenarios, please, utilize the `disableRow` prop,
   * and handle the data with care.
   */
  @Prop({ default: true }) hideBaseAttributes!: boolean;

  /**
   * This optional prop provides "freedom" to the parent/consumer
   * to define the criteria for an attribute row to be disabled
   * by supplying a function to execute for each attribute spec
   * in the AttributeRow component.
   *
   * This function should take a `GroupLevelAttributeWithSpec` type
   * and return a boolean. (`true` disables the attribute row.)
   *
   * Disabling an attribute row will prevent:
   * - The attribute row from being selected/deselected
   * - The specs of the attribute row from being modified
   */
  @Prop(Function) disableRow!: (spec: GroupLevelAttributeWithSpec) => boolean;
  @Prop() selectedAttributes!: GroupLevelAttributeWithSpec[];
  @Prop() deletedAttributes!: GroupLevelAttributeWithSpec[];

  // For permissions matrix
  @Prop() groupTypeName!: string;
  @Prop(Number) groupId!: number;

  // pagination and search variables
  perPage: number = 50;
  page: number = 1;
  sortColumn: string = 'attributeLabel';
  sortOrder: SortOrder = SortOrder.ASC;
  searchPhrase: string = '';

  /**
   * Local copies of attribute spec prop and state.
   * Initiated with empty array.
   */
  clonedSelection: GroupLevelAttributeWithSpec[] = [];
  clonedDeletedSelection: GroupLevelAttributeWithSpec[] = [];
  allAttributes: GroupLevelAttributeWithSpec[] = [];
  clonedPaginatedAttributes: GroupLevelAttribute[] = [];

  @Action('admin/getPaginatedGroupLevelAttributes')
  getPaginatedGroupLevelAttributes!: (
    options: FilteredGroupLevelAttributesPayload
  ) => void;

  @State(
    ({ admin }: RootState) => admin.apiState.getPaginatedGroupLevelAttributes
  )
  getPaginatedGroupLevelAttributesApiState!: ApiState;

  @State(({ admin }: RootState) => admin.paginatedGroupLevelAttributes)
  paginatedGroupLevelAttributes!: Pagination<GroupLevelAttribute>;

  mounted() {
    // Reset search phrase on mount
    this.searchPhrase = '';

    this.fetchPaginatedGroupLevelAttributes();

    if (this.selectedAttributes && this.deletedAttributes) {
      this.clonedSelection = clone(this.selectedAttributes);
      this.clonedDeletedSelection = clone(this.deletedAttributes);
    }
  }

  get AttributesList() {
    return AttributesList;
  }

  get totalItems(): number {
    return this.paginatedGroupLevelAttributes
      ? this.paginatedGroupLevelAttributes.totalItems
      : 0;
  }

  get isAllowedToReadGroupLeveAttributes(): boolean {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.READ,
      'group_administration-groups-read_groups-read_groups_attributes'
    );
  }

  /**
   * Constructs and returns object/option used to fetch templates.
   */
  get filterPayload(): FilteredGroupLevelAttributesPayload {
    return {
      attributeLabel: this.searchPhrase,
      limit: this.perPage,
      page: this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };
  }

  get attributesPage() {
    return {
      name: 'admin-group-management',
      query: {
        tab: 'Attributes',
        attributeLabel: this.searchPhrase,
        limit: this.perPage.toString() || '50',
        page: this.page.toString() || '1',
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder || SortOrder.ASC
      }
    };
  }

  /**
   * Conditionally returns the function to execute for each attribute spec.
   *
   * If parent/consumer supplies their own function,
   * that supplied function will be used instead of the default function.
   */
  get disableRowFunction(): (spec: GroupLevelAttributeWithSpec) => boolean {
    if (this.disableRow instanceof Function) {
      return this.disableRow;
    }

    return this.defaultDisabler;
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  /**
   * Unified method to fetch templates with given options for drier code.
   *
   * This method is only FETCHING the templates,
   * it doesn't update the route query.
   *
   * Because getGroupTemplates is synchronous,
   * the route query updating only happens in the callback function on fetch success.
   */
  @Debounce(700)
  fetchPaginatedGroupLevelAttributes() {
    const fetchQueryOption: FilteredGroupLevelAttributesPayload = {
      ...this.filterPayload
    };
    this.getPaginatedGroupLevelAttributes(fetchQueryOption);
  }

  /**
   * Close modal on click.
   * Should there be any template changes, in group management page,
   * it should be reflected when modal opens up again.
   */
  handleLinkClick(): void {
    this.discardChangesAndClose();
  }

  /**
   * This modal disables base attributes by default.
   *
   * Note:
   * "Hiding" them ≠ disabling them.
   */
  defaultDisabler(spec: GroupLevelAttributeWithSpec): boolean {
    return isDefaultGroupAttribute(spec);
  }

  handleSort(sortSpecs: SortSpecs) {
    this.sortColumn = sortSpecs.sortColumn;
    this.sortOrder = sortSpecs.sortOrder;

    this.fetchPaginatedGroupLevelAttributes();
  }

  handlePaginator(paginator: PaginatorSpecs) {
    this.perPage = paginator.perPage;
    this.page = paginator.page;

    this.fetchPaginatedGroupLevelAttributes();
  }

  handleSearch(): void {
    this.fetchPaginatedGroupLevelAttributes();
  }

  /**
   * Data massager for the main attribute list.
   *
   * It does a few things to the data:
   * 1. Filter out default attributes from the list
   * 2. Map attribute specifications onto existing attribute data
   * 3. Filter attributes that matched the search phrase (optional)
   */
  transformRawAttributeData(
    rawAttributes: GroupLevelAttribute[]
  ): GroupLevelAttributeWithSpec[] {
    return (
      rawAttributes
        // Removes default attributes from the main list
        .filter((rawAttribute) => {
          if (this.hideBaseAttributes) {
            return isDefaultGroupAttribute(rawAttribute) === false;
          }

          return true;
        })

        /**
         * This mapping is to identify if an attribute is selected, and if so,
         * their spec values will take precedence. Else, the default values will be used.
         */
        .map((filteredRawAttributes) => {
          const targetAttr = this.clonedSelection.find(
            (selectedAttr) => selectedAttr.id === filteredRawAttributes.id
          );

          const groupLevelAttributeSpec = targetAttr
            ? targetAttr.groupLevelAttributeSpec
            : { id: undefined, order: 0, isRequired: false };

          return {
            ...filteredRawAttributes,
            groupLevelAttributeSpec,
            mapId: targetAttr ? targetAttr.mapId : undefined,
            mapStatus: targetAttr ? targetAttr.mapStatus : undefined
          };
        })
    );
  }

  /**
   * Adds all SELECTABLE items in CURRENT PAGE to selection
   * (selectable = not disabled)
   */
  selectAllInCurrentPage() {
    const selectedAttrIds = this.clonedSelection.map((spec) => spec.id);
    const attrsToAdd: GroupLevelAttributeWithSpec[] = [];

    this.allAttributes
      .filter((currPageSpec) => {
        const isSelectable = this.disableRowFunction(currPageSpec) === false;
        return isSelectable;
      })
      .forEach((spec) => {
        if (selectedAttrIds.includes(spec.id!) === false) {
          attrsToAdd.push(spec);
        }
      });

    this.clonedSelection = [...this.clonedSelection, ...attrsToAdd];
  }

  /**
   * Remove all DESELECTABLE items in CURRENT PAGE from selection
   * (deselectable = not disabled)
   *
   * (Achieved by retaining only the ones that ARE disabled.)
   */
  removeAllInCurrentPage() {
    const currPageAttrIds = this.allAttributes.map((spec) => spec.id);

    const attrsToRetain = this.clonedSelection.filter((spec) => {
      /**
       * There are a few scenarios when the selected row
       * is NOT visible in the current page.
       *
       * 1. When displaying search results
       * 2. When viewing other pages
       *
       * This function only targets items in current page.
       */
      const attrInOtherPage = currPageAttrIds.includes(spec.id) === false;
      const shouldRetain = this.disableRowFunction(spec) === true;

      if (attrInOtherPage || shouldRetain) {
        return true;
      }
    });

    this.clonedSelection = clone(attrsToRetain);
  }

  addAttribute(item: GroupLevelAttributeWithSpec) {
    this.clonedSelection.push(item);
  }

  removeAttribute(item: GroupLevelAttributeWithSpec) {
    this.clonedSelection.forEach((spec, index, arr) => {
      if (item.id === spec.id) {
        arr.splice(index, 1);
      }
    });
    // Explicit set order and status to DELETE
    this.clonedDeletedSelection.push({
      ...item,
      mapStatus: GroupLevelAttributeTemplateMapStatus.DELETE,
      groupLevelAttributeSpec: {
        ...item.groupLevelAttributeSpec,
        order: 0
      }
    });
  }

  /**
   * User can toggle the "locked" and "required" spec freely without
   * the attribute being selected.
   *
   * So this function only updates the attribute spec visually.
   * It also has to update 2 copies of the attributes.
   */
  updateRowSpecVisual(updatedSpec: GroupLevelAttributeWithSpec) {
    // Update 1: in main array
    const copyOfAllAttributes = clone(this.allAttributes);
    const index = copyOfAllAttributes.findIndex(
      (spec) => spec.id === Number(updatedSpec.id)
    );

    if (index > -1) {
      copyOfAllAttributes[index] = updatedSpec;
      this.allAttributes = copyOfAllAttributes;
    }

    // Update 2: in selection array
    const copyOfSelection = clone(this.clonedSelection);
    const indexInSelection = copyOfSelection.findIndex(
      (spec) => Number(spec.id) === Number(updatedSpec.id)
    );

    if (indexInSelection > -1) {
      copyOfSelection[indexInSelection] = updatedSpec;
      this.clonedSelection = copyOfSelection;
    }
  }

  // Emits updated selection on CTA click
  updateSelection() {
    const updatePayload: ActiveDeletedAttributes = {
      active: this.clonedSelection,
      deleted: this.clonedDeletedSelection
    };
    this.$emit('updateSelection', updatePayload);
    this.discardChangesAndClose();
  }

  discardChangesAndClose() {
    this.$emit('close');
  }

  @Watch('getPaginatedGroupLevelAttributesApiState', { immediate: true })
  onGetPaginatedGroupLevelAttributesApiStateChange(state: ApiState) {
    if (state.success) {
      this.allAttributes = this.transformRawAttributeData(
        this.paginatedGroupLevelAttributes.items
      );
    }

    if (state.error) {
      Toast.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: 'Error getting existing attributes. Please try again.'
      });
    }
  }
}
