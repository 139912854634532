













































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '../../../../store/store';
import { ApiState } from '../../../../store/types/general.types';
import { ToastProgrammatic as Toast } from 'buefy';
import draggable from 'vuedraggable';
import {
  ActiveDeletedAttributes,
  CreateGroupLevelAttributeTemplateResponse,
  GroupLevelAttributeType,
  UpdateGroupLevelAttributeTemplateResponse
} from '@/store/modules/admin/types/group-level-attribute.types';
import {
  clone,
  debouncer,
  isDefaultGroupAttribute
} from '@/jbi-shared/util/group-level-attributes.util';
import DraggableIcon from '@/views/AdminGroupSettings/components/GroupLevelAttributes/DraggableIcon.vue';
import CreateNewGroupLevelAttributeModal from '@/views/AdminGroupSettings/components/GroupLevelAttributes/CreateNewGroupLevelAttributeModal.vue';
import {
  AttributeOptions,
  DataActionType,
  GroupLevelAttribute,
  GroupLevelAttributeTemplateMapStatus,
  GroupLevelAttributeTemplate,
  GroupLevelAttributeWithSpec
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import ModifiedAttributeWrapper from './ModifiedAttributeWrapper.vue';
import EditAttributeModal from '../EditAttributeModal.vue';
import {
  CreateGroupLevelAttributeTemplateDTO,
  UpdateGroupLevelAttributeTemplateDTO
} from '@/jbi-shared/dto/jaas-group-level-attributes.dtos';
import ExistingGroupLevelAttributesModal from '@/views/components/PaginatedGroupLevelAttributes/ExistingGroupLevelAttributesModal.vue';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import BaseLoading from '@/components/base/BaseLoading.vue';

@Component({
  components: {
    draggable,
    DraggableIcon,
    BaseLoading,
    ModifiedAttributeWrapper
  }
})
export default class EditGroupTemplateModal extends Vue {
  @Prop() templateId!: number;
  @Prop() isUpdate!: boolean;

  isSubmitLoading: boolean = false;
  isTemplateNameVerifying: boolean = false;
  shouldUpdateListingOnClose: boolean = false;

  deletedAttributes: GroupLevelAttributeWithSpec[] = [];

  currentTemplateName: string = '';
  clonedGroupAttributeTypes: GroupLevelAttributeType[] = [];

  activeGroupLevelAttributesWithSpecs: GroupLevelAttributeWithSpec[] = [];
  templateNameError: boolean = false;
  templateNameErrorMsg: string = '';
  templateNameMsgType: string = '';

  get activeGLAs() {
    return this.activeGroupLevelAttributesWithSpecs;
  }

  /**
   * Actions and States: Group level attribute types
   */
  @Action('admin/getGroupLevelAttributeTypes')
  getGroupLevelAttributeTypes!: () => void;

  @State(({ admin }: RootState) => admin.apiState.getGroupLevelAttributeTypes)
  getGroupLevelAttributeTypesState!: ApiState;

  @State(({ admin }: RootState) => admin.groupLevelAttributeTypes)
  groupAttributeTypes!: GroupLevelAttributeType[] | undefined;

  /**
   * Actions and States: Group template
   */
  @Action('admin/getGroupLevelAttributeTemplateById')
  getGroupLevelAttributeTemplateById!: (templateId: number) => void;

  @State(
    ({ admin }: RootState) => admin.apiState.getGroupLevelAttributeTemplateById
  )
  getGroupLevelAttributeTemplateByIdApiState!: ApiState;

  @State(({ admin }: RootState) => admin.groupLevelAttributeTemplate)
  groupLevelAttributeTemplate!: GroupLevelAttributeTemplate;

  @Action('admin/verifyGroupLevelAttributeTemplateName')
  verifyGroupLevelAttributeTemplateName!: (templateName: string) => any;

  @State(
    ({ admin }: RootState) =>
      admin.apiState.verifyGroupLevelAttributeTemplateName
  )
  verifyGroupLevelAttributeTemplateNameApiState!: ApiState;

  @State(({ admin }: RootState) => admin.verifyGroupLevelAttributeTemplateName)
  isDuplicateName!: boolean;

  @Action('admin/createGroupLevelAttributeTemplate')
  createGroupLevelAttributeTemplate!: (
    payload: CreateGroupLevelAttributeTemplateDTO
  ) => void;

  @State(
    ({ admin }: RootState) => admin.apiState.createGroupLevelAttributeTemplate
  )
  createGroupLevelAttributeTemplateApiState!: boolean;

  @State(({ admin }: RootState) => admin.newGroupLevelAttributeTemplate)
  newGroupLevelAttributeTemplate!: CreateGroupLevelAttributeTemplateResponse;

  @State(({ admin }: RootState) => admin.updatedGroupLevelAttributeTemplate)
  updatedGroupLevelAttributeTemplate!: UpdateGroupLevelAttributeTemplateResponse;

  @Action('admin/updateGroupLevelAttributeTemplate')
  updateGroupLevelAttributeTemplate!: (
    payload: UpdateGroupLevelAttributeTemplateDTO
  ) => void;

  @State(
    ({ admin }: RootState) => admin.apiState.updateGroupLevelAttributeTemplate
  )
  updateGroupLevelAttributeTemplateApiState!: ApiState;

  get PermissionsMatrixActionsEnum() {
    return PermissionsMatrixActionsEnum;
  }

  get canAddExistingGLAs(): boolean {
    return isUserAllowed(
      PermissionsMatrixActionsEnum.UPDATE,
      'group_administration-groups-update_groups-update_group_attributes-add_existing_group_attributes'
    );
  }

  get canCreateNewGLAs(): boolean {
    return isUserAllowed(
      PermissionsMatrixActionsEnum.CREATE,
      'group_administration-groups-update_groups-update_group_attributes-create_group_attributes'
    );
  }

  created() {
    this.getGroupLevelAttributeTypes();
  }

  mounted() {
    if (this.templateId && this.isUpdate) {
      this.getGroupLevelAttributeTemplateById(this.templateId);
    }
    this.clearNameFieldAttr(); // manually clear previous verify results
  }

  hasFormError(): boolean {
    const isTemplateNameEmpty: boolean = this.currentTemplateName === '';

    const hasDefaultAttributeError: boolean = this.activeGroupLevelAttributesWithSpecs.some(
      (attribute) => isDefaultGroupAttribute(attribute)
    );

    const labelError: boolean = this.activeGroupLevelAttributesWithSpecs.some(
      (attribute) => {
        return attribute.label === '';
      }
    );

    const emptyAttributesError: boolean =
      this.activeGroupLevelAttributesWithSpecs.length === 0;

    if (
      !isTemplateNameEmpty &&
      !hasDefaultAttributeError &&
      !labelError &&
      !emptyAttributesError &&
      !this.templateNameError
    ) {
      return false;
    }

    return (
      isTemplateNameEmpty ||
      hasDefaultAttributeError ||
      labelError ||
      emptyAttributesError ||
      this.templateNameError
    );
  }

  /**
   * check if new template name is the same before calling api
   */
  handleTemplateNameChange(newTemplateName: string): void {
    if (newTemplateName.trim() === '') {
      this.templateNameError = true;
      this.templateNameErrorMsg = 'Template name cannot be empty.';
      this.templateNameMsgType = 'is-danger';
      return;
    }

    this.clearNameFieldAttr();

    if (this.isUpdate) {
      // Additional verification on template name during update
      if (newTemplateName !== this.groupLevelAttributeTemplate.name) {
        this.handleTemplateNameVerification();
      }
    } else {
      this.handleTemplateNameVerification();
    }
  }

  handleTemplateNameVerification() {
    this.isTemplateNameVerifying = true;
    debouncer(
      800,
      this.verifyGroupLevelAttributeTemplateName,
      this.currentTemplateName
    );
  }

  clearNameFieldAttr(): void {
    this.templateNameError = false;
    this.templateNameErrorMsg = '';
    this.templateNameMsgType = '';
  }

  handleAttributeDrag(): void {
    this.updateAttributeOrdering();
  }

  updateAttributeOrdering(): void {
    this.activeGroupLevelAttributesWithSpecs.forEach((attribute, index) => {
      attribute.groupLevelAttributeSpec.order = index + 1;
    });
  }

  closeModal(): void {
    this.$emit('close');
  }

  handleSubmit(): void {
    if (this.isUpdate) {
      this.updateGroupLevelAttributeTemplate({
        templateId: this.templateId,
        templateName: this.currentTemplateName,
        attributes: {
          active: this.sanitizeAttributesAsPayload(
            this.activeGroupLevelAttributesWithSpecs,
            DataActionType.UPDATE
          ),
          deleted: this.sanitizeAttributesAsPayload(
            this.deletedAttributes,
            DataActionType.DELETE
          )
        }
      });
      return;
    }

    this.createGroupLevelAttributeTemplate({
      templateName: this.currentTemplateName,
      attributes: this.sanitizeAttributesAsPayload(
        this.activeGroupLevelAttributesWithSpecs,
        DataActionType.CREATE
      )
    });
  }

  openAddExistingAttributesModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: ExistingGroupLevelAttributesModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      fullScreen: true,
      props: {
        selectedAttributes: this.activeGroupLevelAttributesWithSpecs,
        deletedAttributes: this.deletedAttributes
      },
      events: {
        updateSelection: (
          updatedAttributesPayload: ActiveDeletedAttributes
        ) => {
          this.activeGroupLevelAttributesWithSpecs =
            updatedAttributesPayload.active;
          this.deletedAttributes = updatedAttributesPayload.deleted;
          this.updateAttributeOrdering();
        }
      }
    });
  }

  handleAttributeUpdate(
    attributeToEdit: GroupLevelAttribute,
    index: number
  ): void {
    this.$buefy.modal.open({
      parent: this,
      component: EditAttributeModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      canCancel: true,
      props: {
        attributeId: Number(attributeToEdit.id)
      },
      events: {
        attributeUpdateSuccess: (
          updatedAttribute: GroupLevelAttribute
        ): void => {
          /**
           * Immutable approach for updating local arrary (create new template) to ensure changes
           * to attributes are reflected. This approach ensures Vue detects the change.
           *
           * For small arrays (dozens of items), the performance impact would be negligible.
           */
          const updatedItem = {
            ...this.activeGroupLevelAttributesWithSpecs[index],
            label: updatedAttribute.label,
            option: updatedAttribute.option
          };

          // Use splice to replace the item at index
          this.activeGroupLevelAttributesWithSpecs.splice(
            index,
            1,
            updatedItem
          );
        }
      }
    });
  }

  handleAttributeRemove(attributeIndex: number): void {
    const attributeToDelete: GroupLevelAttributeWithSpec = this
      .activeGroupLevelAttributesWithSpecs[attributeIndex];
    this.assignDeleteValue(attributeToDelete);
    this.deletedAttributes.push(attributeToDelete);
    this.activeGroupLevelAttributesWithSpecs.splice(attributeIndex, 1);
    this.updateAttributeOrdering();
  }

  assignDeleteValue(attribute: GroupLevelAttributeWithSpec): void {
    attribute.mapStatus = GroupLevelAttributeTemplateMapStatus.DELETE;
    attribute.groupLevelAttributeSpec.order = 0;
  }

  handleCreateNewAttribute(): void {
    this.$buefy.modal.open({
      parent: this,
      component: CreateNewGroupLevelAttributeModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      canCancel: true,
      props: {
        attributes: this.activeGroupLevelAttributesWithSpecs,
        allAttributeTypes: this.clonedGroupAttributeTypes
      },
      events: {
        addNewAttribute: (newAttribute: GroupLevelAttributeWithSpec) => {
          this.activeGroupLevelAttributesWithSpecs.push(newAttribute);
          this.updateAttributeOrdering();

          Toast.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `New attribute added`
          });
        }
      }
    });
  }

  /**
   * Sanitizes attributes into AttributeOptions payload based on action type and attribute state.
   * For update operations:
   * - New attributes (no id) are treated as CREATE
   * - Existing attributes (with id) are treated as UPDATE
   * For delete operations:
   * - All attributes are treated as DELETE
   *
   * @param attributes Array of attributes to sanitize
   * @param actionType The primary action type (helps determine base behavior)
   * @returns Array of sanitized AttributeOptions
   */
  sanitizeAttributesAsPayload(
    attributes: GroupLevelAttributeWithSpec[],
    actionType: DataActionType
  ): AttributeOptions[] {
    return attributes.map((attribute) => {
      const basePayload: AttributeOptions = {
        groupLevelAttributeId: attribute.id!,
        order: attribute.groupLevelAttributeSpec.order,
        isRequired: attribute.groupLevelAttributeSpec.isRequired
      };

      // For DELETE action, always include additional fields
      if (actionType === DataActionType.DELETE) {
        return {
          ...basePayload,
          mapId: attribute.mapId!,
          groupLevelAttributeSpecId: attribute.groupLevelAttributeSpec.id,
          status: attribute.mapStatus
        };
      }

      // For UPDATE action, check if attribute is new or existing
      if (actionType === DataActionType.UPDATE) {
        // If attribute has no map id or spec id, treat as new (CREATE)
        if (!attribute.groupLevelAttributeSpec.id || !attribute.mapId) {
          return basePayload;
        }

        // Otherwise, treat as existing (UPDATE)
        return {
          ...basePayload,
          mapId: attribute.mapId!,
          groupLevelAttributeSpecId: attribute.groupLevelAttributeSpec.id,
          status: GroupLevelAttributeTemplateMapStatus.ACTIVE
        };
      }

      // For CREATE action, return base payload
      return basePayload;
    });
  }

  @Watch('verifyGroupLevelAttributeTemplateNameApiState', {
    deep: true,
    immediate: true
  })
  verifyTemplateNameApiStateCallback(verifyState: ApiState): void {
    this.isTemplateNameVerifying = false;

    if (verifyState.success) {
      if (this.currentTemplateName === '') {
        return;
      }

      // if (this.isTemplateWithSameNameExisted) {
      if (this.isDuplicateName) {
        this.templateNameError = true;
        this.templateNameErrorMsg = 'Template name already existed.';
        this.templateNameMsgType = 'is-danger';
        return;
      }

      this.templateNameError = false;
      this.templateNameErrorMsg = 'Template name is available.';
      this.templateNameMsgType = 'is-success';
    }

    if (verifyState.error) {
      this.templateNameError = true;
      this.templateNameErrorMsg = verifyState.error.message;
      this.templateNameMsgType = 'is-danger';
    }
  }

  @Watch('getGroupLevelAttributeTypesState', { deep: true })
  getAttributeTypeApiStateCallback(getTypeApiState: ApiState): void {
    if (getTypeApiState.success) {
      this.clonedGroupAttributeTypes = clone(
        this.groupAttributeTypes
      ) as GroupLevelAttributeType[];
    }

    if (getTypeApiState.error) {
      Toast.open('Error getting group level attributes.');
    }
  }

  @Watch('getGroupLevelAttributeTemplateByIdApiState', { deep: true })
  onGetGroupLevelAttributeTemplateByIdApiStateChange(apiState: ApiState) {
    if (apiState.success) {
      // Cloned to prevent directly mutating state
      this.activeGroupLevelAttributesWithSpecs = clone(
        this.groupLevelAttributeTemplate.groupLevelAttributesWithSpecs
      );
      this.currentTemplateName = this.groupLevelAttributeTemplate.name;
      this.templateNameError = this.currentTemplateName === '';
    }

    if (apiState.error) {
      Toast.open(`Error in template update. Please try again.`);
    }
  }

  @Watch('updateGroupLevelAttributeTemplateApiState', { deep: true })
  onUpdateGroupLevelAttributeTemplateApiStateChange(apiState: ApiState) {
    if (apiState.success) {
      Toast.open({
        type: 'is-success',
        message: `Template updated successfully.`
      });

      const {
        updatedGroupLevelAttributeTemplate
      } = this.updatedGroupLevelAttributeTemplate;

      this.$emit(
        'updateSuccess',
        Number(updatedGroupLevelAttributeTemplate.id)
      );
      this.closeModal();
    }

    if (apiState.error) {
      Toast.open(`Error in template update. Please try again.`);
    }
  }

  @Watch('createGroupLevelAttributeTemplateApiState', { deep: true })
  onCreateGroupLevelAttributeTemplateApiStateChange(apiState: ApiState) {
    if (apiState.success) {
      Toast.open({
        type: 'is-success',
        message: `Template created successfully.`
      });

      const {
        createdGroupLevelAttributeTemplate
      } = this.newGroupLevelAttributeTemplate;

      this.$emit(
        'createSuccess',
        Number(createdGroupLevelAttributeTemplate.id)
      );
      this.closeModal();
    }

    if (apiState.error) {
      Toast.open(`Error in template creation. Please try again.`);
    }
  }

  // Generic loading callback
  @Watch('updateGroupLevelAttributeTemplateApiState.loading')
  @Watch('createGroupLevelAttributeTemplateApiState.loading')
  toggleLoaderOnApiStateLoading(isLoading: boolean): void {
    this.isSubmitLoading = isLoading;
  }
}
